import React from "react"
import { Box, Text } from "@chakra-ui/react"

const NotFoundPage = ():JSX.Element => {
  return (
    <Box p={8}>
      <Text fontSize="xl">This is a 404</Text>
    </Box>
  )
}

export default NotFoundPage;
